import { GeneralStorefrontSimpleIcon, Tooltip } from '@outdoorsyco/bonfire';
import { SVGProps } from 'react';
import { useIntl } from 'react-intl';

interface IProBadgeProps {
  Icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  className?: string;
  width?: number;
  height?: number;
  onMouseEnter?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLSpanElement>) => void;
}

export const ProBadge = ({
  Icon = GeneralStorefrontSimpleIcon,
  className,
  width = 24,
  height = 24,
  onMouseEnter,
  onMouseLeave,
}: IProBadgeProps) => {
  const intl = useIntl();
  return (
    <Tooltip
      content={intl.formatMessage({
        defaultMessage:
          'This host operates as a business, ensuring a reliable and smooth booking experience.',
        id: 'Kdx/I5',
      })}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <Icon width={width} height={height} />
    </Tooltip>
  );
};
