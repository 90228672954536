import { Divider, ETextStyleVariant, ETooltipSize, Text, Tooltip } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { TCurrency } from '@/config/locales';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { getSearchViewedEventData } from '@/redux/selectors/analytics-selectors';
import { getMapMarkers } from '@/redux/selectors/search/map';
import { trackPriceBreakdownPreviewedEvent } from '@/services/analytics/listings';
import { EMapStatus } from '@/services/analytics/maps/types';
import { TEstimatedPriceBreakdown } from '@/services/types/search/rentals/id';
import { formatCurrency } from '@/utility/currency';
import { IRentalTile } from '@/utility/mapSearchResultToTile';

type TPriceBreakdownItemProps = {
  label: React.ReactNode;
  amount: number;
  currency: TCurrency;
};

const PriceBreakdownItem: React.FC<TPriceBreakdownItemProps> = ({ label, amount, currency }) => (
  <div className="flex justify-between">
    <Text variant={ETextStyleVariant.MediumRegular} className="text-gray-900">
      {label}
    </Text>
    <Text variant={ETextStyleVariant.MediumRegular} className="text-gray-900">
      {formatCurrency({
        priceInCents: amount,
        currency,
        digits: 2,
      })}
    </Text>
  </div>
);

type TTotalPriceWithTooltipProps = {
  rentalTile: IRentalTile;
  pricingBreakdown: TEstimatedPriceBreakdown;
  rentalType?: string;
  isCampground?: boolean;
};

export const TotalPriceWithTooltip: React.FC<TTotalPriceWithTooltipProps> = ({
  rentalTile,
  pricingBreakdown,
  rentalType = 'rv',
  isCampground = false,
}) => {
  const {
    delivery_fee,
    duration,
    guest_fee,
    insurance,
    prep_fee,
    rental_amount,
    rental_amount_per_day,
    total,
    use_day_pricing,
  } = pricingBreakdown;
  const { currency, id, instantBook, price } = rentalTile;
  const nightsTotal = (price?.currentPrice || 0) * duration;
  const { isAboveXL } = useBreakpoint();
  const markers = useSelector(getMapMarkers);
  const searchEventData = useSelector(getSearchViewedEventData);
  const currentPageViewed = searchEventData?.currentPageViewed || 1;
  const mapStatus = isAboveXL ? EMapStatus.COLLAPSED : 'hidden';
  const discountPercentOff = price?.discount
    ? Math.round((price.discount / price.originalPrice) * 100)
    : 0;
  const totalPrice = nightsTotal + delivery_fee + prep_fee + guest_fee + insurance;

  const handleMouseEnter = () => {
    if (id) {
      const eventData = {
        basePricePerNight: price?.originalPrice || 0,
        currentPageViewed,
        deliveryFee: delivery_fee,
        discountPercentOff,
        duration,
        serviceFee: guest_fee,
        insurance: insurance,
        isCampground,
        isInstabook: instantBook || false,
        mapStatus,
        markersOnMap: markers.length,
        pricePerNight: price?.currentPrice || 0,
        prepFee: prep_fee,
        rentalAmount: rental_amount,
        rentalAmountPerDay: rental_amount_per_day,
        rentalID: id,
        rentalType,
        total,
        useDayPricing: use_day_pricing,
      };

      trackPriceBreakdownPreviewedEvent(eventData);
    }
  };

  const tooltipContent = (
    <div className="space-y-4">
      <Text variant={ETextStyleVariant.LargeBold} className="text-black">
        <FormattedMessage defaultMessage="Estimated total breakdown" id="7ySI+c" />
      </Text>
      <div className="space-y-3">
        <PriceBreakdownItem
          label={
            <FormattedMessage
              defaultMessage="{pricePerNight} × {numberOfNights} nights"
              id="g4/Cqv"
              values={{
                pricePerNight: formatCurrency({
                  priceInCents: price?.currentPrice || 0,
                  currency,
                  digits: 2,
                }),
                numberOfNights: duration,
              }}
            />
          }
          amount={nightsTotal}
          currency={currency || 'USD'}
        />
        {insurance > 0 && (
          <PriceBreakdownItem
            label={<FormattedMessage defaultMessage="Insurance" id="qK9GW3" />}
            amount={insurance}
            currency={currency || 'USD'}
          />
        )}
        {prep_fee > 0 && (
          <PriceBreakdownItem
            label={<FormattedMessage defaultMessage="Prep fee" id="LOKQfC" />}
            amount={prep_fee}
            currency={currency || 'USD'}
          />
        )}
        {guest_fee > 0 && (
          <PriceBreakdownItem
            label={<FormattedMessage defaultMessage="Service fee" id="G0bXT7" />}
            amount={guest_fee}
            currency={currency || 'USD'}
          />
        )}
        {delivery_fee > 0 && (
          <PriceBreakdownItem
            label={<FormattedMessage defaultMessage="Delivery fee" id="jCB9UK" />}
            amount={delivery_fee}
            currency={currency || 'USD'}
          />
        )}
        <Divider />
        <div className="flex justify-between">
          <Text variant={ETextStyleVariant.MediumRegular} className="text-gray-900">
            <FormattedMessage defaultMessage="Est. total" id="KvXyhS" />
          </Text>
          <Text variant={ETextStyleVariant.MediumRegular} className="text-gray-900">
            {formatCurrency({ priceInCents: totalPrice, currency, digits: 2 })}
          </Text>
        </div>
      </div>
    </div>
  );

  return (
    <Tooltip
      content={tooltipContent}
      placement="top"
      size={ETooltipSize.Large}
      className="pt-1"
      onMouseEnter={handleMouseEnter}
      aria-label="Price breakdown">
      <Text
        component="span"
        variant={ETextStyleVariant.SmallRegular}
        className="text-gray-400 underline">
        <FormattedMessage
          defaultMessage="{total} Est. total"
          id="BKLfj/"
          values={{
            total: formatCurrency({
              priceInCents: total,
              currency,
              digits: 2,
            }),
          }}
        />
      </Text>
    </Tooltip>
  );
};
